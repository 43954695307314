action:
  add_file: "Ajouter le justificatif"
  validate: "Valider"
business_card:
  button: "Ouvrir la fiche du contact"
  steps:
    android:
      first: "Appuyez sur le bouton Télécharger dans la popup de téléchargement du fichier vCard."
      second: "Une fois le fichier téléchargé, appuyez sur le bouton Ouvrir pour enregistrer le contact."
    ios:
      first: "Ouvrez le fichier téléchargé et appuyez sur l’icône de l’application Contacts."
      second: "La fiche du contact s’ouvre, appuyez sur le bouton Enregistrer pour sauvegarder le contact."
    safari:
      first: "Défilez jusqu’en bas de la fiche et appuyez sur le bouton Nouveau contact."
      second: "La fenêtre Nouveau contact s’ouvre, appuyez sur le bouton OK pour enregistrer le contact."
button:
  accept_all: "Tout accepter"
  back: "Retour"
  continue: "Continuer"
  cookies_policy: "politique d'utilisation des cookies"
  customize: "Personnaliser"
  discover_brand_offer: "Découvrir l'offre manager.one"
  download: "Télécharger"
  reject_all: "Tout refuser"
  save: "Enregistrer"
  send: "Envoyer"
cookies:
  consent:
    description: "Comme tous les sites, nous utilisons des cookies pour recueillir des informations sur votre utilisation de la Carte connectée, dans le but de l'améliorer. Consultez notre {cookiesPolicyLink} pour en savoir plus."
    title: "Politique d’utilisation des cookies"
  preferences:
    analytics:
      description: "Les cookies analytiques sont utilisés pour comprendre comment les visiteurs interagissent avec le site Web. Ces cookies aident à fournir des informations sur le nombre de visiteurs, le taux de rebond, la source de trafic, etc..."
      name: "Analytique (optionnel)"
    functional:
      description: "Les cookies fonctionnels sont nécessaires au bon fonctionnement du site Web. Ces cookies assurent les fonctionnalités de base et les fonctions de sécurité du site Web, de manière anonyme."
      name: "Fonctionnel (requis)"
    title: "Préférences des cookies"
error:
  message: "Veuillez rescanner la carte."
  subtitle: "Une erreur est survenue."
  title: "Oups"
general:
  offered_by: "Proposé par"
message:
  info:
    cookie:
      link: "/fr/cookies"
  warning:
    form_not_valid: "Certaines informations sont manquantes ou erronées. Veuillez vérifier les champs indiqués ci-dessous"
    unknown: "Une erreur inconnue est survenue, si le problème persiste, merci de contacter le support"
placeholder:
  mileage: "Saisir le kilométrage ..."
success:
  operation:
    update: "Votre opération a été enregistrée avec succès"
table:
  amount: "Montant"
vcard:
  bred:
    presentation:
      title: "Découvrez la nouvelle fonctionnalité Carte Connectée par BRED "
  macsf:
    presentation:
      title: "Découvrez la nouvelle fonctionnalité Carte Connectée par MACSF.one"
  mone:
    presentation:
      title: "Découvrez la nouvelle fonctionnalité Carte Connectée par manager.one "
  presentation:
    description: "La fonctionnalité Carte Connectée utilise la technologie NFC intégrée à la carte de paiement @:{'brand.name'}. Elle permet à son titulaire de partager facilement une carte de visite virtuelle (vCard) ou un lien internet personnalisé par simple scan de sa carte avec un téléphone équipé de la technologie NFC."
    file:
      text: "Cette option permet de partager un fichier de type image ou vidéo, un document Microsoft Word, Excel, Powerpoint ou Adobe PDF."
      title: "Partager un fichier"
    link:
      text: "Cette option permet de partager par exemple le lien vers votre profil LinkedIn, le site internet de votre société, un catalogue produit, ou encore une vidéo."
      title: "Partager un site internet personnalisé"
    virtual:
      text: "Cette option permet de partager une carte de visite virtuelle (vCard) contenant l’ensemble des informations du titulaire de la carte (coordonnées, adresse email, n° de téléphone, profils de réseaux sociaux...)"
      title: "Partager sa carte de visite virtuelle"
  qnb:
    presentation:
      title: "Découvrez la nouvelle fonctionnalité Carte Connectée par QNB"
  sbe:
    presentation:
      title: "Découvrez la nouvelle fonctionnalité Carte Connectée par SBE"
