action:
  add_file: "Beleg hinzufügen"
  validate: "Bestätigen"
business_card:
  button: "Öffnen Sie die Kontaktkarte"
  steps:
    android:
      first: "Klicken Sie im Popup-Fenster zum Herunterladen der vcard-Datei auf die Schaltfläche Herunterladen."
      second: "Drücken Sie nach dem Herunterladen der Datei die Schaltfläche Öffnen, um den Kontakt zu speichern."
    ios:
      first: "Öffnen Sie die heruntergeladene Datei und tippen Sie auf das Symbol für die Anwendung Kontakte."
      second: "Die Karteikarte des Kontakts wird geöffnet. Tippen Sie auf die Schaltfläche Speichern, um den Kontakt zu speichern."
    safari:
      first: "Scrollen Sie zum Ende der Karte und tippen Sie auf die Schaltfläche Neuer Kontakt."
      second: "Das Fenster „Neuer Kontakt“ wird geöffnet. Drücken Sie die Schaltfläche „OK“, um den Kontakt zu speichern."
button:
  accept_all: "Alles akzeptieren"
  back: "Zurück"
  continue: "Weiter"
  cookies_policy: "Cookie-Richtlinie"
  customize: "Anpassen"
  discover_brand_offer: "Entdecken Sie das Angebot von manager.one"
  download: "Herunterladen"
  reject_all: "Alles ablehnen"
  save: "Speichern"
  send: "Senden"
cookies:
  consent:
    description: "Wie alle Websites verwenden auch wir Cookies, um Informationen über Ihre Nutzung der Connected Card zu sammeln und diese zu verbessern. Besuchen Sie unsere {cookiesPolicyLink}, um mehr darüber zu erfahren."
    title: "Richtlinien für die Verwendung von Cookies"
  preferences:
    analytics:
      description: "Analytische Cookies werden verwendet, um zu verstehen, wie Besucher mit der Website interagieren. Diese Cookies helfen dabei, Informationen über die Anzahl der Besucher, die Absprungrate, die Quelle des Datenverkehrs usw. bereitzustellen."
      name: "Analytisch (optional)"
    functional:
      description: "Funktionale Cookies sind für das ordnungsgemäße Funktionieren der Website erforderlich. Diese Cookies stellen die grundlegenden Funktionen und Sicherheitsfunktionen der Website auf anonymer Basis sicher."
      name: "Funktional (erforderlich)"
    title: "Cookie-Einstellungen"
error:
  message: "Bitte scannen Sie die Karte erneut."
  subtitle: "Ein Fehler ist aufgetreten."
  title: "Ups"
general:
  offered_by: "Vorgeschlagen von"
message:
  info:
    cookie:
      link: "/en/cookies"
  warning:
    form_not_valid: "Einige Informationen fehlen oder sind falsch. Bitte füllen Sie die folgenden Felder aus"
    unknown: "Es ist ein unbekannter Fehler aufgetreten. Wenn das Problem weiterhin besteht, wenden Sie sich bitte an den Support."
placeholder:
  mileage: "Kilometerstand eingeben ..."
success:
  operation:
    update: "Ihre Operation wurde erfolgreich registriert"
table:
  amount: "Betrag"
vcard:
  bred:
    presentation:
      title: "Entdecken Sie die neue Connected Card-Funktion von BRED"
  macsf:
    presentation:
      title: "Entdecken Sie die neue Connected Card-Funktion von MACSF.one"
  mone:
    presentation:
      title: "Entdecken Sie die neue Connected Card-Funktion von manager.one "
  presentation:
    description: "Die Funktion Connected Card nutzt die NFC-Technologie, die in die @:{'brand.name'} Zahlungskarte integriert ist. Sie ermöglicht es dem Karteninhaber, eine virtuelle Visitenkarte (vCard) oder einen personalisierten Internetlink durch einfaches Scannen der Karte mit einem NFC-fähigen Telefon zu teilen."
    file:
      text: "Mit dieser Option können Sie eine Datei vom Typ Bild oder Video, ein Microsoft Word-, Excel-, Powerpoint- oder Adobe PDF-Dokument freigeben."
      title: "Eine Datei teilen"
    link:
      text: "Mit dieser Option können Sie z. B. den Link zu Ihrem LinkedIn-Profil, Ihrer Firmenwebsite, einem Produktkatalog oder einem Video teilen."
      title: "Eine eigene Website teilen"
    virtual:
      text: "Mit dieser Option können Sie eine virtuelle Visitenkarte (vCard) teilen, die alle Informationen des Karteninhabers enthält (Kontaktdaten, E-Mail-Adresse, Tel.-Nr., Profile in sozialen Netzwerken...)."
      title: "Seine virtuelle Visitenkarte teilen"
  qnb:
    presentation:
      title: "Entdecken Sie die neue Connected Card-Funktion von QNB"
  sbe:
    presentation:
      title: "Entdecken Sie die neue Connected Card-Funktion von SBE"
